import Settings from "./Settings";
import Link from "./Link";
import SavePopup from "./SaveAsTemplate";
import Signature from "./Signature";
import { Path, Transforms } from "slate";

const OtherSettings = {
  link: Link,
  settings: Settings,
  saveAsTemplate: SavePopup,
  signatureSettings: Signature,
};

export default OtherSettings;

export const removeSign = (editor, path = []) => {
  const pPath = path?.split("|")?.map((m) => parseInt(m));
  const element_path = [...pPath, 0];

  Transforms.removeNodes(editor, { at: element_path }); // remove signature

  Transforms.insertNodes(
    editor,
    [
      {
        type: "signature",
        children: [{ text: "" }],
        data: { name: "", email: "", date: "" },
      },
    ],
    { at: element_path }
  );

  const parentPath = Path.parent(element_path);

  Transforms.setNodes(
    editor,
    {
      childType: "signature",
    },
    { at: parentPath }
  );
};
