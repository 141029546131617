import { useEffect, useState, createContext, useContext, useMemo } from "react";
import { getSelectedText } from "../utils/helper";
import { debounce } from "../helper";

const EditorContext = createContext();

export const useEditorSelection = (editor) => {
  const [textSelected, setTextSelected] = useState(false);
  useEffect(() => {
    if (editor?.selection) {
      const text = getSelectedText(editor);
      if (text?.length > 0) {
        setTextSelected(true);
      } else {
        setTextSelected(false);
      }
    }
  }, [editor?.selection]);

  return [textSelected];
};

export const EditorProvider = ({ editor, theme, children }) => {
  const [previous, setPrevious] = useState("");
  const [drop, setDrop] = useState(0);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedElement, setSelectedElement] = useState({});
  const [dragging, setDragging] = useState({});
  const [event] = useMouseMove(window.dragging);
  const path = event?.target?.getAttribute("data-path");
  const [popupType, setPopupType] = useState(""); // opened popup name in the editor will be stored
  const [openAI, setOpenAI] = useState("");
  const [contextMenu, setContextMenu] = useState({ path: null });
  const [fontFamilies, setFontFamilies] = useState({});

  useEffect(() => {
    window.updateSelectedItem = (d) => {
      setSelectedElement(d);
    };
  }, []);

  const onDrop = () => {
    setDrop(drop + 1);
  };

  const isSelectedElement = (path, type) => {
    const cu_path = path.split("|");
    const se_path = selectedElement?.path?.split("|");
    const isParentSelected =
      se_path?.slice(0, cu_path.length).join("|") === path && type === "parent";
    // check if child or parent is selected
    if (selectedElement?.path === path || isParentSelected) {
      return selectedElement;
    }
    return {};
  };

  const value = useMemo(() => {
    if (path) {
      setPrevious(path);
      return { hoverPath: path };
    } else {
      return { hoverPath: previous };
    }
  }, [path, editor?.selection]);

  const updateDragging = (d) => {
    if (d) {
      // console.log("Updating dragOver", d);
      setDragging((prevState) => {
        return {
          ...prevState,
          ...d,
        };
      });
    }
  };

  const otherValues = useMemo(
    () => ({
      ...(value || {}),
      onDrop: onDrop,
      drop,
      theme: theme,
      selectedPath: selectedPath,
      setSelectedPath: setSelectedPath,
      selectedElement: selectedElement,
      setSelectedElement: setSelectedElement,
      isSelectedElement: isSelectedElement,
      dragging: { ...dragging } || {},
      popupType,
      setPopupType,
      setContextMenu,
      contextMenu,
      openAI,
      setOpenAI,
      updateDragging,
      fontFamilies,
      setFontFamilies,
    }),
    [
      path,
      editor?.selection,
      selectedPath,
      selectedElement,
      contextMenu,
      openAI,
      popupType,
      drop,
    ]
  );

  return (
    <EditorContext.Provider value={otherValues}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => {
  return useContext(EditorContext);
};

const useMouseMove = (dragging) => {
  const [event, setEvent] = useState({ target: null });
  useEffect(() => {
    addListener();
    return () => {
      removeListener();
    };
  }, []);

  const onMouseMove = (e) => {
    if (!dragging?.id) {
      const dpath = e?.target?.closest(".dpath");
      if (dpath) {
        setEvent({ target: dpath });
      }
    }
  };

  const debounceMouseMove = debounce(onMouseMove, 100);

  const addListener = () => {
    document.addEventListener("mousemove", debounceMouseMove);
  };

  const removeListener = () => {
    document.removeEventListener("mousemove", debounceMouseMove);
  };

  return [event];
};

export default useMouseMove;
