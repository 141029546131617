import React from "react";
import { Editor, Element } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";

const TableRow = (props) => {
  const { attributes, children, element } = props;
  const { bgColor, borderColor } = element;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [tableNode] = Editor.nodes(editor, {
    at: path,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
  });

  const [tableProps] = tableNode || [{}];

  const rowBorderColor = borderColor || tableProps?.borderColor;

  return (
    <tr
      {...attributes}
      style={{
        backgroundColor: bgColor,
        border: rowBorderColor ? `1px solid ${rowBorderColor}` : "",
      }}
    >
      {children}
    </tr>
  );
};

export default TableRow;
