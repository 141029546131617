import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import dividerStyle from "../../common/StyleBuilder/dividerStyles";

const DividerPopup = (props) => {
    const { element, onSave, onClose, onDelete, customProps } = props;
    return (
        <StyleBuilder
            title="Divider"
            type="dividerStyle"
            element={element}
            onSave={onSave}
            onClose={onClose}
            renderTabs={dividerStyle}
            onDelete={onDelete}
            customProps={customProps}
        />
    );
};

export default DividerPopup;
