import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiPicker =(props) => {
  const { onEmojiSelect, onClose, theme = 'light' } = props;
  return (
    <>
          <Picker data={data} onEmojiSelect={onEmojiSelect} onClickOutside={onClose} theme={theme} />
    </>
  );
};

export default EmojiPicker;
