export const settingsLabel = {
  text: "Text Settings",
  button: "Button Settings",
  image: "Image Settings",
  video: "Embed or Video Settings",
  box: "Box Settings",
  appHeader: "App Header Settings",
  form: "Form Settings",
  table: "Table Settings",
  embedScript: "Code Settings",
  divider: 'Divider Settings'
};

export const ItemTypes = {
  text: "Text",
  button: "Button",
  image: "Image",
  video: "Embed or Video",
  box: "Box",
  appHeader: "App Header",
  form: "Form",
  table: "Table",
  embedScript: "Code",
  signature: "Signature",
  divider:'Divider'
};
