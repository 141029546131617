import Icon from "../Icon";

const dividerStyle = [
    {
        tab: "Border",
        value: "sectionBorderRadius",
        fields: [
            {
                label: "Divider Color",
                key: "borderColor",
                type: "color",
                infoIcon: <Icon icon="info" />,
            },
            {
                label: "Divider Width",
                key: "borderWidth",
                type: "text",
                placeholder: "1px",
                width: 6,
            },
            {
                label: "Divider Style",
                key: "borderStyle",
                type: "textOptions",
                width: 6,
                options: [
                    {
                        text: "Solid",
                        value: "solid",
                    },
                    {
                        text: "Dotted",
                        value: "dotted",
                    },
                    {
                        text: "Dashed",
                        value: "dashed",
                    },
                    {
                        text: "Double",
                        value: "double",
                    },
                    {
                        text: "Groove",
                        value: "groove",
                    },
                    {
                        text: "Ridge",
                        value: "ridge",
                    },
                    {
                        text: "Inset",
                        value: "inset",
                    },
                    {
                        text: "Outset",
                        value: "outset",
                    },
                ],
                renderOption: (option) => {
                    return <span>{option.text}</span>;
                },
            },
        ],
    }
];

export default dividerStyle;
