import React, { useState } from "react";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import Icon from "../../common/Icon";
import { isBlockActive } from "../../utils/SlateUtilityFunctions";
import { insertEmbed } from "../../utils/embed";
import "./Embed.css";
import ToolbarIcon from "../../common/ToolbarIcon";
import ImageSelector from "../../common/ImageSelector/ImageSelector";

const EMBED_LABEL = {
  image: "Image",
  video: "Video",
  embed: "Embed",
};

const Embed = ({ editor, format, lbT, customProps, icoBtnType }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    url: "",
    alt: "",
    images: [],
  });
  const [selection, setSelection] = useState();

  const handleFormSubmit = (fd) => {
    if (fd?.url || fd?.images.length > 0) {
      selection && Transforms.select(editor, selection);
      selection && ReactEditor.focus(editor);
      insertEmbed(editor, { ...fd }, format);
      setFormData({
        url: "",
        alt: "",
      });
    }
    handleClose();
  };

  const onToggle = (e) => {
    e.preventDefault();
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectImage = (img) => {
    const fd = {
      ...formData,
      url: img,
    };
    setFormData(fd);
    let extProps = {};
    if (format === "video") {
      extProps = {
        aspectRatio: "16 / 9",
      };
    }
    handleFormSubmit({ ...fd, ...extProps });
  };

  return (
    <>
      <ToolbarIcon
        className={isBlockActive(editor, format) ? "active" : ""}
        title={EMBED_LABEL[lbT || format]}
        onClick={onToggle}
        icon={<Icon icon={lbT || format} />}
        icoBtnType={icoBtnType}
      />
      <ImageSelector
        open={open}
        onClose={handleClose}
        customProps={customProps}
        onSelectImage={onSelectImage}
        title={EMBED_LABEL[lbT || format]}
      />
    </>
  );
};

export default Embed;
// export default styled(commonStyle)(Embed);
