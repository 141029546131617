import React from "react";
import { Node } from "slate";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import SaveAsTemplate from "../../../StyleBuilder/fieldTypes/saveAsTemplate";
import { CloseIcon } from "../../../iconslist";

const SavePopup = (props) => {
  const { classes, onClose, elementProps } = props;

  return (
    <Dialog open={true} fullWidth sx={classes.dialog}>
      <DialogTitle>
        Save As Template
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SaveAsTemplate
          {...props}
          data={{ key: "saveAsTemplate" }}
          saveText={"Save"}
          handleClose={onClose}
          elementProps={elementProps}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SavePopup;
