import React from "react";
import {
  MdFormatQuote,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdAdd,
  MdArrowForward,
  MdOutlinePermMedia,
  MdFormatAlignJustify,
} from "react-icons/md";
import {
  BsCameraVideoFill,
  BsArrowBarRight,
  BsArrowBarLeft,
  BsCodeSlash,
} from "react-icons/bs";
import { FaSuperscript, FaSubscript } from "react-icons/fa";
import { FcAddRow, FcAddColumn } from "react-icons/fc";
import {
  AiFillEdit,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowRight,
  AiOutlineDelete,
  AiFillTag,
  AiOutlineUpload,
  AiOutlineArrowsAlt,
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowLeft,
  AiFillHtml5,
} from "react-icons/ai";
import { SiLatex } from "react-icons/si";
import { RiDeleteColumn, RiDeleteRow } from "react-icons/ri";
import { CiGrid32 } from "react-icons/ci";
import {
  FontFamilyIcon,
  FontSizeIcon,
  StrikethroughIcon,
  AppHeader,
  MoreHorizontal,
  UploadImage,
  LeftArrow,
  RightArrow,
  CheckListButton,
  CheckListButtonActive,
  ExcelIcon,
  CsvIcon,
  CloseIcon,
  SearchIcon,
  ExpandIcon,
  Text,
  TextAreaIcon,
  Phone,
  BriefCase,
  Bank,
  CalendarTick,
  DollarSquare,
  Checkbox,
  Description,
  RadioButtonIcon,
  CheckedIcon,
  UncheckedIcon,
  InfinityIcon,
  ResetIcon,
} from "./iconslist";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  AccordionTextFormatIcon,
  BoldTextFormatIcon,
  BulletedListTextFormatIcon,
  ButtonElementIcon,
  CarouselElementIcon,
  CheckListTextFormatIcon,
  ColorBoxElementIcon,
  DividerElementIcon,
  DocUploadElementIcon,
  EmbedElementIcon,
  EmojiElementIcon,
  FormElementIcon,
  GridElementIcon,
  H1,
  H2,
  H3,
  ImageElementIcon,
  ItalicTextFormatIcon,
  LinkIconV2,
  OrderedListTextFormatIcon,
  SignatureElementIcon,
  TableElementIcon,
  TopBannerElementIcon,
  UnderlineTextFormatIcon,
  VideoElementIcon,
  CalendlyIcon,
} from "./iconListV2";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SettingsIcon from "../assets/svg/SettingsIcon";
import UndoIcon from "../assets/svg/UndoIcon";
import RedoIcon from "../assets/svg/RedoIcon";
import TextIcon from "../assets/svg/TextIcon";
import AddElementIcon from "../assets/svg/AddElementIcon";
import AddTemplateIcon from "../assets/svg/AddTemplateIcon";
import OpenLinkIcon from "../assets/svg/OpenLinkIcon";

const iconList = {
  fontFamily: <FontFamilyIcon size={20} />,
  fontSize: <FontSizeIcon size={20} />,
  // bold: <MdFormatBold size={20} />,
  bold: <BoldTextFormatIcon size={20} />,
  // italic: <MdFormatItalic size={20} />,
  italic: <ItalicTextFormatIcon size={20} />,
  // strikethrough: <MdStrikethroughS size={20} />,
  strikethrough: <StrikethroughIcon size={20} />,
  // underline: <MdFormatUnderlined size={20} />,
  underline: <UnderlineTextFormatIcon size={20} />,
  headingOne: <H1 />,
  headingTwo: <H2 size={18} fill="#64748B" />,
  headingThree: <H3 size={18} fill="#64748B" />,
  blockquote: <MdFormatQuote size={20} fill="#64748B" />,
  doublequote: <MdFormatQuote size={20} fill="#64748B" />,
  superscript: <FaSuperscript size={15} fill="#64748B" />,
  subscript: <FaSubscript size={15} fill="#64748B" />,
  alignLeft: <MdFormatAlignLeft size={18} fill="#64748B" />,
  alignCenter: <MdFormatAlignCenter size={18} fill="#64748B" />,
  alignRight: <MdFormatAlignRight size={18} fill="#64748B" />,
  alignJustify: <MdFormatAlignJustify size={18} fill="#64748B" />,
  orderedList: <OrderedListTextFormatIcon size={18} fill="#64748B" />,
  unorderedList: <BulletedListTextFormatIcon size={18} fill="#64748B" />,
  // link: <MdInsertLink size={20} />,
  link: <LinkIconV2 size={20} />,
  // image: <MdImage size={20} />,
  image: <ImageElementIcon size={20} />,
  // video: <MdVideoLibrary size={20} />,
  video: <VideoElementIcon />,
  add: <MdAdd size={20} />,
  // table: <AiOutlineTable size={20} />,
  table: <TableElementIcon size={20} />,
  insertRowBelow: <AiOutlineInsertRowBelow size={25} />,
  insertColumnRight: <AiOutlineInsertRowRight size={25} />,
  insertColumnLeft: <AiOutlineInsertRowLeft size={25} />,
  insertRowAbove: <AiOutlineInsertRowAbove size={25} />,
  trashCan: <AiOutlineDelete size={25} />,
  addId: <AiFillTag size={20} />,
  upload: <AiOutlineUpload size={20} />,
  equation: <SiLatex size={20} />,
  resize: <AiOutlineArrowsAlt size={20} />,
  videoPlayer: <BsCameraVideoFill size={20} />,
  insertHtml: <AiFillHtml5 size={20} />,
  arrowRight: <MdArrowForward size={35} />,
  pen: <AiFillEdit size={20} />,
  emoji: <EmojiElementIcon size={20} fill="#64748B" />,
  grid: <GridElementIcon />,
  accordion: <AccordionTextFormatIcon />,
  signature: <SignatureElementIcon />,
  button: <ButtonElementIcon />,
  carousel: <CarouselElementIcon />,
  form: <FormElementIcon />,
  tableCellResizeLeft: <BsArrowBarRight size={20} />,
  tableCellResizeRight: <BsArrowBarLeft size={20} />,
  deleteColumn: <RiDeleteColumn size={20} />,
  addColumn: <FcAddColumn size={20} />,
  deleteRow: <RiDeleteRow size={20} />,
  addRow: <FcAddRow size={20} />,
  "check-list-item": <CheckListTextFormatIcon size={20} />,
  embed: <EmbedElementIcon />,
  topbanner: <TopBannerElementIcon size={20} fill="#64748B" />,
  fileUpload: <UploadImage />,
  media: <MdOutlinePermMedia size={20} />,
  accordionArrow: <ArrowRightIcon size={20} />,
  accordionArrowDown: <ArrowDropDownIcon size={20} />,
  appHeader: <AppHeader />,
  moreHorizantal: <MoreHorizontal />,
  docsUpload: <DocUploadElementIcon />,
  colorbox: <ColorBoxElementIcon style={{ fill: "#64748B" }} size={20} />,
  moreVertical: <MoreVertRoundedIcon />,
  leftArrow: <LeftArrow />,
  rightArrow: <RightArrow />,
  checkListButton: <CheckListButton />,
  checkListButtonActive: <CheckListButtonActive />,
  excelIcon: <ExcelIcon />,
  csvIcon: <CsvIcon />,
  divider: <DividerElementIcon />,
  SearchIcon: <SearchIcon />,
  expandIcon: <ExpandIcon />,
  closeIcon: <CloseIcon />,
  embedScript: (
    <BsCodeSlash className="fg-op-ico" size={20} style={{ fill: "#64748B" }} />
  ),
  freegrid: <CiGrid32 size={20} style={{ fill: "#64748B" }} />,
  text: <Text stroke={"#64748B"} />,
  calenderNewIcon: <CalendlyIcon />,
  textArea: <TextAreaIcon />,
  phone: <Phone />,
  briefCase: <BriefCase />,
  bank: <Bank />,
  calendarTick: <CalendarTick />,
  dollarSquare: <DollarSquare />,
  description: <Description />,
  radioButton: <RadioButtonIcon />,
  checkbox: <Checkbox />,
  mail: (
    <EmailRoundedIcon sx={{ width: "18px", height: "16px", fill: "#000" }} />
  ),
  info: (
    <InfoOutlinedIcon
      sx={{
        width: "14px",
        height: "14px",
        paddingLeft: "5px",
        marginTop: "5px",
        fill: "#94A3B8",
      }}
    />
  ),
  checkedIcon: <CheckedIcon />,
  uncheckedIcon: <UncheckedIcon />,
  infinityIcon: <InfinityIcon />,
  resetIcon: <ResetIcon />,
  pagesSettings: <SettingsIcon />,
  undoIcon: <UndoIcon />,
  redoIcon: <RedoIcon />,
  textIcon: <TextIcon />,
  addElement: <AddElementIcon />,
  addTemplate: <AddTemplateIcon />,
  openLinkIcon: <OpenLinkIcon />,
};

export const icons = {
  ...iconList,
};

const Icon = (props) => {
  const { icon } = props;
  return iconList[icon] ? iconList[icon] : <div>dummy {icon}</div>;
};

export default Icon;
