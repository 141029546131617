import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

const TEMP_NODE_ID = "temp-focus-node";

const ensureTemporaryFocusNode = (editor, selectedDOM) => {
  // Check if the temporary focus node already exists
  const { left, top } = selectedDOM?.getBoundingClientRect();
  const [tempNodeEntry] = Editor.nodes(editor, {
    at: [],
    match: (n) => n.temp === TEMP_NODE_ID,
  });

  if (!tempNodeEntry) {
    // If not, insert the temporary focus node at the start of the editor
    Transforms.insertNodes(
      editor,
      {
        type: "temp",
        temp: TEMP_NODE_ID,
        children: [{ type: "restrictedType", text: "" }],
        left,
        top,
      },
      { at: [editor.children.length] }
    );
  }
};

export const focusUsingTemporaryNode = (editor, selectedDOM) => {
  Transforms.deselect(editor);

  // Ensure the temporary node exists
  ensureTemporaryFocusNode(editor, selectedDOM);

  // Select the temporary node without causing scroll
  const [tempNodeEntry] = Editor.nodes(editor, {
    at: [],
    match: (n) => n.temp === TEMP_NODE_ID,
  });

  if (tempNodeEntry) {
    const [, path] = tempNodeEntry;
    Transforms.select(editor, path);
  }

  ReactEditor.focus(editor);
};

export const cleanupTemporaryFocusNode = (editor) => {
  // Remove the temporary focus node if it exists
  for (const [, path] of Editor.nodes(editor, {
    at: [],
    match: (n) => n.temp === TEMP_NODE_ID,
  })) {
    Transforms.removeNodes(editor, { at: path });
  }
};

export const dragOverOn = (previousPath, currentPath) => {
  try {
    const previousElement = document.querySelector(
      `[data-dragoverid="${previousPath}"]`
    );
    const currentElement = document.querySelector(
      `[data-dragoverid="${currentPath}"]`
    );
    // Remove 'dragOver' class from the current element, if it exists
    if (previousElement && previousElement.classList.contains("rnd-dragOver")) {
      previousElement.classList.remove("rnd-dragOver");
    }

    // Add 'dragOver' class to the new element
    if (currentElement) {
      currentElement.classList.add("rnd-dragOver");
    }
  } catch (err) {
    console.log(err);
  }
};
