
const Styles = (theme) => ({
  drawerContainer: {
    "&.MuiDrawer-root": {
      zIndex: 1301
    },
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme.palette.containers.card
    },
  },
  childContainer: {
    padding: "20px",
    '&.emojiDrawer': {
      padding: 'unset',
      paddingTop: '10px'
    }
  }
});

export default Styles;