import React from "react";
import UndoIcon from "../../assets/svg/UndoIcon";

const UndoButton = () => {


  return (
    <UndoIcon />
  );
};

export default UndoButton;
