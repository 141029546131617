import React, { useState } from "react";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import { ReactEditor, useSlateStatic } from "slate-react";
import { IconButton, Tooltip } from "@mui/material";
import { GridSettingsIcon } from "../../common/iconslist";
import DividerPopup from "./DividerPopup";
import { Transforms } from "slate";

const Divider = (props) => {
  const { attributes, children, element,  customProps } = props;
  const { theme } = useEditorContext();
  const { borderColor = theme?.palette?.primary?.main || '#0F172A', borderWidth = '1px', borderStyle = 'solid' } = element;
  const { readOnly } = customProps;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [openSettings, setOpenSettings] = useState(false);
  const { hoverPath } = useEditorContext();
  const [showTool] = useEditorSelection(editor);
  const selected = hoverPath === path.join(",");
  const width = borderWidth?.includes('px') ? borderWidth : `${borderWidth}px`
  const onSettings = () => {
    setOpenSettings(true);
  };

  const DividerToolbar = ({ selected, showTool, onSettings }) => {
    return <div
        contentEditable={false}
        className="divider-settings"
        style={{ top: "-20px", left: 0 }}
      >
        <Tooltip title="Divider Settings" arrow>
        <IconButton size="small" sx={{
          background: theme?.palette?.type === 'dark' ? theme?.palette?.greyshades?.light8 : theme?.palette?.containers?.card, border: theme?.palette?.type === 'dark' ? '1px solid #E4E8EB33' : 'none', boxShadow: '0px 0px 4px 0px #00000040', borderRadius: '50%', '& svg': { stroke: theme?.palette?.text?.secondary3 }, 
          '&.MuiIconButton-root:hover': { background: theme?.palette?.type === 'dark' ? `${theme?.palette?.greyshades?.light8} !important` : `${theme?.palette?.containers?.card} !important` }}} onClick={onSettings}>
            <GridSettingsIcon />
          </IconButton>
        </Tooltip>
      </div>
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    if (path) {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  return (
    <div
      {...attributes}
      className="dividerComponent"
      style={{ userSelect: "none", position: 'relative' }}
    >
      {!readOnly && (
        <div
          className={`element-root element-selector`}
          contentEditable={false}
          style={{ zIndex: 1000}}
        >
          <DividerToolbar
            selected={selected}
            showTool={showTool}
            onSettings={onSettings}
          />
        </div>
      )}
      <hr
        contentEditable={false}
        className="editorDivider"
        style={{
          userSelect: "none",
          borderTop: !borderColor?.includes("linear")
            ? `${width} ${borderStyle} ${borderColor}`
            : `transparent`,
          backgroundImage: borderColor?.includes("linear") ? borderColor : "none",
          height: borderColor?.includes("linear") ? borderWidth : undefined,
        }}
      />
      <span style={{ display: "none" }}>{children}</span>
      {openSettings ? (
        <DividerPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default Divider;
