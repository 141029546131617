const SectionStyle = (themeReact, theme) => ({
  root: {
    "&:hover": {
      "& .dh-para": {
        opacity: 1,
      },
      "& .sectionIcon": {
        opacity: 1,
      },
      "& .sectionPopper": {
        opacity: 1,
      },
    },
    "& .element-toolbar": {
      "&:hover": {
        display: "block",
      },
    },
    "& .sectionPopper": {
      opacity: 0,
      "&:hover": {
        opacity: 1,
      },
    },
    "& .sectionIcon": {
      opacity: 0,
      padding: "0px",
      background: "transparent",
      border: "none",
      width: "20px",
      height: "20px",
      "& button": {
        boxShadow: "none",
        background: "transparent",
        width: "20px",
        height: "20px",
      },
      "& svg": {
        fill: "#ccc",
        width: "20px",
        marginTop: "-5px",
      },
      "&:hover": {
        opacity: 1,
        background: theme?.palette?.editor?.sectionSettingIconHover,
      },
      "&.active": {
        opacity: 1,
      },
    },
    "& .ed-section-inner": {
      [themeReact.breakpoints.between("xs", "md")]: {
        maxWidth: `320px !important`,
      },
    },
    "&.is-temp": {
      position: "fixed",
      background: "transparent",
      padding: "12px",
      width: "10px",
      height: "10px",
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      margin: "auto",
      // left: "var(--left)",
      // top: "var(--top)",
      zIndex: 1000,
      pointerEvents: "none",
    },
  },
});

export default SectionStyle;
