import React from "react";
import RedoIcon from "../../assets/svg/RedoIcon";

const RedoButton = () => {


  return (
    <RedoIcon/>
  );
};

export default RedoButton;