import { Box, Drawer, SwipeableDrawer } from "@mui/material";
import DrawerStyles from "./style";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useEditorContext } from "../../hooks/useMouseMove";
import { Fragment } from "react";

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme?.palette?.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  margin: "auto",
  marginTop: "14px",
}));

function SwipeableDrawerComponent({ open, onClose, children, customClass, swipeableDrawer, disableSwipeToOpen }) {
  const { theme } = useEditorContext();
  const classes = DrawerStyles(theme);

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  }
  return (
    <Fragment>
      {
        swipeableDrawer ?
          <SwipeableDrawer
            disableSwipeToOpen={disableSwipeToOpen}
            anchor="bottom"
            open={open}
            onClose={handleClose}
            sx={classes.drawerContainer}
          >
            <Puller />

            <Box className={customClass} sx={classes.childContainer}>
              {children}
            </Box>
          </SwipeableDrawer>
          :
          <Drawer
            disableSwipeToOpen={true}
            anchor="bottom"
            open={open}
            onClose={handleClose}
            sx={classes.drawerContainer}
          >
            <Puller />

            <Box className={customClass} sx={classes.childContainer}>
              {children}
            </Box>
          </Drawer>
      }
    </Fragment>
  );
}
SwipeableDrawerComponent.defaultProps = {
  open: false,
  onClose: () => { },
  customClass: '',
  swipeableDrawer: true,
  disableSwipeToOpen: true
}

export default SwipeableDrawerComponent;