import SignaturePopup from "../../../../Elements/Signature/SignaturePopup";
import { Path, Transforms } from "slate";
import { formatDate } from "../../../../utils/helper";

const Signature = (props) => {
  const { onClose, customProps, editor, path } = props;

  const pPath = path?.split("|")?.map((m) => parseInt(m));
  const element_path = [...pPath, 0];

  const onSave = (data = {}) => {
    onClear();
    Transforms.insertNodes(
      editor,
      [
        {
          type: "sign",
          signature: null,
          fontFamily: "",
          signedBy: "",
          signedOn: formatDate(new Date(), "MM/DD/YYYY"),
          signedText: "",
          children: [{ text: "" }],
          ...data,
        },
      ],
      { at: element_path }
    );

    const parentPath = Path.parent(element_path);

    Transforms.setNodes(
      editor,
      {
        childType: "sign",
      },
      { at: parentPath }
    );
  };

  const onClear = () => {
    Transforms.removeNodes(editor, { at: element_path });
  };

  return (
    <>
      <SignaturePopup
        onSave={onSave}
        onClear={onClear}
        handleClose={onClose}
        customProps={customProps}
        onlyPopup={true}
      />
    </>
  );
};

export default Signature;
