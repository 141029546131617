import { useMediaQuery } from "@mui/material";

export const STIMULATOR_MOCK = {
  xs: "@media (min-width: 1200px) and (max-width: 1980px)",
};

export const isStimulator = () => {
  try {
    const breakpoint = document.getElementsByClassName("et-wrpr")[0];
    const etDataset = breakpoint?.dataset;
    if (etDataset?.breakpoint) {
      return etDataset?.breakpoint;
    }
  } catch (err) {
    console.log(err);
  }
};

const useBreakpoints = (theme) => {
  const lg = useMediaQuery(theme.breakpoints.up("md"));
  const xs = useMediaQuery(theme.breakpoints.between("xs", "md"));

  // if stimulated environment
  const breakpoint = document.getElementsByClassName("et-wrpr")[0];
  const etDataset = breakpoint?.dataset;
  if (etDataset?.breakpoint) {
    return etDataset?.breakpoint;
  }

  if (lg) {
    return "lg";
  } else if (xs) {
    return "xs";
  } else {
    return "lg";
  }
};

export default useBreakpoints;
