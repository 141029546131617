import { useState, useCallback, useRef } from "react";

const initialState = {
  id: null,
  active: false,
  isDragging: 0,
  dragOver: false,
  dragOverType: null,
};

const useDragging = () => {
  const [dragging, setDragging] = useState({
    ...initialState,
  });

  // Ref to keep the latest draggingStatus
  const draggingRef = useRef(dragging);

  const updateDragging = (newStatus) => {
    setDragging((prevStatus) => {
      const updatedStatus = { ...prevStatus, ...newStatus };
      draggingRef.current = updatedStatus; // Update the ref to hold latest status
      return updatedStatus;
    });
  };

  const startDragging = useCallback((data) => {
    // console.log("dragissue", "startDragging");
    updateDragging({ ...data });
  }, []);

  const stopDragging = useCallback(() => {
    // console.log("dragissue", "stopDragging");
    updateDragging({ ...initialState });
  }, []);

  const setDragOver = useCallback((isOver) => {
    updateDragging({
      dragOver: isOver,
    });
  }, []);

  return {
    dragging,
    draggingRef,
    startDragging,
    stopDragging,
    setDragOver,
  };
};

export default useDragging;
