import React, { useState } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Menu,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserInputs from "./UserInputs";

const FormWorkflow = (props) => {
  const {
    schedule,
    element,
    setSchedule,
    scheduleEvery,
    setScheduleEvery,
    currentInstant,
    scheduleOn,
    setScheduleOn,
    subject,
    setSubject,
    bodyData,
    setBodyData,
    handleSelectedVariables,
    handleInputReset,
    classes,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const variables = element?.children;

  const type = 1;

  const handleBodyField = (data) => {
    // setBodyData(data);
  };

  const onSaveBodyField = (data) => {
    let bodyData = typeof data === "string" ? JSON.parse(data) : data;
    setBodyData(bodyData);
  };

  const handleScheduleInstant = (event) => {
    setScheduleEvery(event.target.value);
  };

  const onHandleChange = (event) => {
    setScheduleOn(event.target.value);
  };

  const onSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleVariables = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={classes.subHeadings}>Set Timings *</Typography>
        <Typography sx={classes.infoText}>
          Invitees will receive reminder emails at specified times before
          scheduled events
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="flex-end">
          <Grid item sx={classes.radioBtn}>
            <RadioGroup name="set timing" value={schedule} defaultValue={1}>
              <FormControlLabel
                value={"immediately"}
                label="Immediately"
                onChange={() => {
                  setSchedule("immediately");
                }}
                control={<Radio size="small" />}
              />
              {/* <FormControlLabel
                value={"after"}
                label="After"
                onChange={() => {
                  setSchedule("after");
                }}
                control={<Radio size="small" />}
              /> */}
            </RadioGroup>
          </Grid>
          {schedule === "after" && (
            <Grid item>
              <Grid container gap={2}>
                <Grid item>
                  <FormControl fullWidth size="small">
                    <Select
                      value={scheduleOn}
                      onChange={onHandleChange}
                      sx={classes.select}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      {currentInstant.map((ele, index) => (
                        <MenuItem
                          sx={classes.selectList}
                          key={`${ele}_${index}`}
                          value={ele}
                        >
                          {ele}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth size="small">
                    <Select
                      value={scheduleEvery}
                      onChange={handleScheduleInstant}
                      sx={classes.select}
                      IconComponent={KeyboardArrowDownIcon}
                      style={{ minWidth: "160px" }}
                    >
                      <MenuItem sx={classes.selectList} value={"minutes"}>
                        Minutes
                      </MenuItem>
                      <MenuItem sx={classes.selectList} value={"hours"}>
                        Hours
                      </MenuItem>
                      <MenuItem sx={classes.selectList} value={"days"}>
                        Day
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.formHeadings}>Subject *</Typography>
        <TextField
          multiline
          margin={"none"}
          id="email_subject"
          value={subject}
          onChange={onSubjectChange}
          fullWidth={true}
          maxRows={5}
          className="workflowSubject"
          sx={{
            "& fieldset": {
              border: "1px solid #6F6F6F33",
              borderRadius: "8px",
            },
            "& textarea": {
              fontSize: "16px",
              fontWeight: 500,
              width: "410px",
            },
            "& textarea:focus-visible": {
              outline: "none",
              borderRadius: "none",
              border: "none",
            },
            "& .MuiOutlinedInput-root": {
              padding: "8px 10px",
            },
          }}
          InputProps={{
            endAdornment: (
              <>
                <Button
                  sx={classes.variableBtn}
                  onClick={handleVariables}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Variables
                </Button>
                <Menu
                  id="basic-menu"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {variables?.map((m, i) => (
                    <MenuItem
                      key={`menu_${i}_${m.name}`}
                      sx={{ color: "#64748B" }}
                      onClick={handleSelectedVariables(m, type)}
                    >
                      {m.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.formHeadings}>Body *</Typography>
        <UserInputs
          element={element}
          type={2}
          subject={bodyData}
          handleField={handleBodyField}
          onSave={onSaveBodyField}
          handleSelectedVariables={handleSelectedVariables}
          handleInputReset={handleInputReset}
        />
      </Grid>
    </Grid>
  );
};

export default FormWorkflow;
