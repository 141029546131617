import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const insertDefaultEmbed = (
  editor,
  type,
  defaultURL = "",
  extProps = {}
) => {
  try {
    const url = defaultURL ? defaultURL : type === "image" ? "" : "";
    insertEmbed(editor, { url, images: [], ...extProps }, type);
  } catch (err) {
    console.log(err);
  }
};

export const createEmbedNode = (type, { url, alt, images, ...rest }) => ({
  type,
  alt,
  url,
  images: images || [],
  children: [{ text: " " }],
  ...(rest || {}),
  size: {
    xs: { widthInPercent: "100", height: 300 },
    sm: { widthInPercent: "100", height: 370 },
    md: { widthInPercent: "100", height: 420 },
    lg: {
      widthInPercent: "100",
      height: 520,
    },
  },
  alignment: { horizantal: "center" },
  objectFit: false,
});

export const insertEmbed = (editor, embedData, format) => {
  try {
    const embed = createEmbedNode(format, embedData);
    Transforms.insertNodes(editor, embed, { at: editor.selection.anchor.path });
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};
