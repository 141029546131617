import React, { useEffect, useState } from "react";
import { Grid, TextField, InputAdornment, Typography, Tooltip } from "@mui/material";
import ColorPickerButton from "../../ColorPickerButton";

const Color = (props) => {
  const { value, data, onChange, classes } = props;
  const { key, label, hideGradient } = data;
  const [recentColors, setRecentColors] = useState({});

  useEffect(() => {
    const storedColors = JSON.parse(localStorage.getItem("recentColors"));
    if (storedColors) {
      setRecentColors(storedColors);
    }
  }, []);

  const onSave = (color) => {
    onChange({
      [key]: color,
    });

    let updatedColors = [];

    if (recentColors[key]) {
      updatedColors = [color, ...recentColors[key]?.filter((c) => c !== color)];
    } else {
      updatedColors?.unshift(color);
    }
    if (updatedColors?.length > 10) {
      updatedColors?.pop();
    }
    updatedColors = { ...recentColors, [key]: updatedColors };
    setRecentColors(updatedColors);
    localStorage.setItem("recentColors", JSON.stringify(updatedColors));
  };

  return (
    <Grid item xs={12} className="btnColorPicker">
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px", display: 'flex', alignItems: 'center', '& svg': { width: '20px', height: '20px' } }}
      >
        {label}
        {data?.infoIcon ?
          <Tooltip arrow title="Note: If color gradient is used, divider styles will not apply">
            <span style={{ display: 'inline-block'}}>
              {data?.infoIcon}
            </span>
          </Tooltip>
          : null
        }
      </Typography>
      <TextField
        fullWidth
        value={value}
        placeholder={`${label} color code`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ColorPickerButton
                classes={classes}
                value={value}
                onSave={onSave}
                recentColors={recentColors[key]}
                hideGradient={hideGradient}
              />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default Color;
