import React from "react";
import { Transforms, Node } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import dividerStyle from "../../../StyleBuilder/dividerStyles";

const DividerSettings = (props) => {
    const { editor, path, customProps } = props;
    const item_path = path?.split("|").map((m) => parseInt(m));
    const element_path = [...item_path, 0];
    const element = Node.get(editor, element_path);
    
    const onChange = (data) => {
        console.log("🚀 ~ onChange ~ data:", data);
        console.log("🚀 ~ onChange ~ element:", element)
        const updated_props = {
            ...element,
            ...data,
            field_type: data?.element,
        };
        delete updated_props.children;
        Transforms.setNodes(
            editor,
            {
                ...updated_props,
            },
            {
                at: element_path,
            }
        );
    };

    const handleClose = () => {
        console.log("close");
    };

    return (
        <Box component={"div"} className="item-w">
            {dividerStyle?.map((m, i) => {
                return (
                    <StyleContent
                        key={`tab_${m.value}_$${i}`}
                        renderTabs={dividerStyle}
                        value={m.value}
                        element={element}
                        onChange={onChange}
                        customProps={customProps}
                        handleClose={handleClose}
                    />
                );
            })}
        </Box>
    );
};

export default DividerSettings;
