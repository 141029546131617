import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from "./Icons/LinkIcon";
import CodeIcon from "@mui/icons-material/Code";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import {
  GridAddSectionIcon,
  SignatureIcon,
  WorkflowIcon,
} from "../../iconslist";
import Delete from "@mui/icons-material/Delete";

const Actions = {
  ai: {
    type: "ai",
    Button: IconButton,
    Icon: AutoAwesomeIcon,
    title: "Auto Generate using AI",
  },
  addSection: {
    type: "addSection",
    Button: Button,
    Icon: null,
    title: "Add Section",
  },
  addElementInSection: {
    type: "addElement",
    Button: IconButton,
    Icon: AddIcon,
    title: "Add Element",
  },
  settings: {
    type: "settings",
    Button: IconButton,
    Icon: SettingsIcon,
    title: "Settings",
    cb: "settings",
  },
  link: {
    type: "link",
    Button: IconButton,
    Icon: LinkIcon,
    title: "Link",
  },
  signatureSettings: {
    type: "signatureSettings",
    Button: IconButton,
    Icon: SignatureIcon,
    title: "Signature",
  },
  removeSign: {
    type: "removeSign",
    Button: IconButton,
    Icon: Delete,
    title: "Remove Sign",
  },
  more: {
    type: "more",
    Button: IconButton,
    Icon: MoreVertIcon,
    title: "More",
    cb: "more",
  },
  drag: {
    type: 1,
    Button: IconButton,
    Icon: DragIndicatorIcon,
    title: "Drag",
  },
  edit: {
    type: 2,
    Button: IconButton,
    Icon: EditIcon,
    title: "Edit",
  },
  close: {
    type: 3,
    Button: IconButton,
    Icon: CloseIcon,
    title: "close",
  },
  moveUp: {
    type: "moveUp",
    Button: IconButton,
    Icon: ArrowUpwardIcon,
    title: "Move Up",
  },
  moveDown: {
    type: "moveDown",
    Button: IconButton,
    Icon: ArrowDownwardIcon,
    title: "Move Down",
  },
  addFormField: {
    type: "addFormField",
    Button: IconButton,
    Icon: GridAddSectionIcon,
    title: "Add Form Field",
  },
  workFlow: {
    type: "workFlow",
    Button: IconButton,
    Icon: WorkflowIcon,
    title: "Workflow",
  },
  embedScript: {
    type: "addEmbedScript",
    Button: IconButton,
    Icon: CodeIcon,
    title: "Embed Code",
  },
  saveAsTemplate: {
    type: "saveAsTemplate",
    Button: IconButton,
    Icon: SaveIcon,
    title: "Save As Template",
  },
  imageFrame: {
    type: "imageFrame",
    Button: IconButton,
    Icon: FilterFramesIcon,
    title: "Image Frame Settings",
  },
};

export default Actions;
